import React, { useEffect, useState } from "react";
import { CardTitle, CardBody, Col, Row, Label } from "reactstrap";
import { Card, CardContent } from "@mui/material";
import { getFileTrackerLog } from "../../../helpers/backend_helper";
import { useNavigate, Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import moment from "moment";
import Spinner from "../../../components/Spinner";

const Main = ({
  handleReturn,
  fileId,
  referenceNo,
  dateSent,
  dateReceived,
}) => {
  const navigate = useNavigate();
  const [fileTrackerLog, setFileTrackerLog] = useState([]);

  const columns = [
    {
      name: "#",
      width: "3rem",
      cell: (row, index) => index + 1, //RDT provides index by default
    },
    {
      name: <span className="font-weight-bold fs-13">File Updated On</span>,
      selector: (row) => moment(row.date_time_updated).format("LLL"),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">File Status</span>,
      selector: (row) => row.file_status,
      width: "15rem",
      sortable: false,
      cell: (row) => {
        switch (row.file_status) {
          case "received_in_registry_from_partner_institution":
            return (
              <span className="badge badge-soft-info">
                {" "}
                Received in Registry from Partner
              </span>
            );
          case "returned_to_registry_by_coordinator":
            return (
              <span className="badge badge-soft-info">
                Returned to Registry By Coordinator
              </span>
            );
          case "sent_to_coordinator_by_registry":
            return (
              <span className="badge badge-soft-success">
                Sent to Coordinator By Registry
              </span>
            );
          case "reopened_file_sent_to_coordinator_by_registry":
            return (
              <span className="badge badge-soft-danger">
                Re-opened File Sent to Coordinator By Registry
              </span>
            );
          case "sent_to_coordinator_by_investigation_officer":
            return (
              <span className="badge badge-soft-success">
                Sent to Coordinator By Investigation Officer
              </span>
            );
          case "assigned_to_investigation_officer":
            return (
              <span className="badge badge-soft-warning">
                Assigned to Investigation Officer
              </span>
            );
          case "returned_to_investigation_officer":
            return (
              <span className="badge badge-soft-danger">
                {" "}
                Returned to Investigation Officer
              </span>
            );
          case "sent_to_partner_institution":
            return (
              <span className="badge badge-soft-secondary">
                {" "}
                Returned to Partner Institution
              </span>
            );
          case "end_file_movement":
            return (
              <span className="badge badge-soft-danger">
                Ended File Movement
              </span>
            );
          case "returned_to_registry_by_investigation_officer":
            return (
              <span className="badge badge-soft-success">
                Returned to Registry By Investigation Officer
              </span>
            );
          default:
            return <Label>None</Label>;
        }
      },
    },
    {
      name: <span className="font-weight-bold fs-13">File Origin</span>,
      selector: (row) => {
        return row.file_status ===
          "received_in_registry_from_partner_institution" &&
          row.file.origin !== null
          ? row.file.origin.acronym
          : "";
      },
      width: "7rem",
      sortable: false,
    },
    {
      name: <span className="font-weight-bold fs-13">Coordinator</span>,
      width: "10rem",
      selector: (row) => {
        return row.coordinator &&
          row.file_status !== "returned_to_registry_by_investigation_officer"
          ? `${row.coordinator.first_name} ${row.coordinator.last_name}`
          : "";
      },
      sortable: false,
    },
    {
      name: <span className="font-weight-bold fs-13">Assigned Officer</span>,
      width: "10rem",
      selector: (row) => {
        return row.assigned_officer
          ? `${row.assigned_officer.first_name} ${row.assigned_officer.last_name}`
          : "";
      },
      sortable: false,
    },
    {
      name: <span className="font-weight-bold fs-13">File Destination</span>,
      selector: (row) => {
        return row.destination ? row.destination.acronym : "";
      },
      width: "7rem",
      sortable: false,
    },
    {
      name: <span className="font-weight-bold fs-13">Regional Office</span>,
      selector: (row) => (row.region !== null ? row.region.description : ""),
      width: "10rem",
      sortable: false,
    },
    {
      name: <span className="font-weight-bold fs-13">Reason</span>,
      width: "15rem",
      sortable: false,
      cell: (row) => {
        switch (row.reason) {
          case "preliminary_investigation":
            return (
              <span className="badge badge-soft-success">
                {" "}
                Preliminary Investigation
              </span>
            );
          case "further_investigation":
            return (
              <span className="badge badge-soft-success">
                Further Investigation
              </span>
            );
          case "legal_opinion":
            return (
              <span className="badge badge-soft-success">Legal Opinion</span>
            );
          case "disciplinary_action":
            return (
              <span className="badge badge-soft-success">
                Disciplinary Action
              </span>
            );
          case "charged_in_court":
            return (
              <span className="badge badge-soft-success">Charged in Court</span>
            );
          case "closed_no_further_action":
            return (
              <span className="badge badge-soft-success">
                Closed - No Further Action
              </span>
            );
          case "closed_no_offence_detected":
            return (
              <span className="badge badge-soft-success">
                Closed - No Offence Detected
              </span>
            );

          case "closed_civil":
            return (
              <span className="badge badge-soft-success">Closed - Civil</span>
            );
          case "further_clarification":
            return (
              <span className="badge badge-soft-success">
                Further Clarification
              </span>
            );
          default:
            return <Label></Label>;
        }
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Offence</span>,
      width: "20rem",
      sortable: false,
      cell: (row) => {
        switch (row.offence) {
          case "money_laundering":
            return (
              <span className="badge badge-soft-info"> Money Laundering</span>
            );
          case "terrorist_financing":
            return (
              <span className="badge badge-soft-info">
                {" "}
                Terrorist Financing
              </span>
            );
          case "proliferation_of_weapons_of_mass_destruction":
            return (
              <span className="badge badge-soft-info">
                {" "}
                Proliferation of Weapons of Mass Destruction
              </span>
            );
          default:
            return <Label></Label>;
        }
      },
    },
  ];

  // Fetch data from API calls
  const fetchFileMovementHistory = () => {
    try {
      getFileTrackerLog(fileId).then((res) => {
        if (res.status === false) {
          navigate("/logout");
        } else {
          setFileTrackerLog(res);
        }
      });
    } catch (error) {
      console.error("Error occured in ", error);
    }
  };

  // React Hooks
  useEffect(() => {
    fetchFileMovementHistory();
  }, []);

  return (
    <Card>
      <CardTitle style={{ margin: "20px" }}>Files</CardTitle>

      <CardBody>
        <div style={{ margin: "20px" }}>
          <button className="btn-primary" onClick={handleReturn}>
            <i className="fas fa-reply me-1 align-middle"> </i> Go back
          </button>
          <button className="btn-info float-right">
            <i className="fas fa-print me-1 align-middle"> </i> Print
          </button>
        </div>
      </CardBody>

      <CardBody>
        <div style={{ margin: "20px" }}>
          <Row>
            <Col>
              <Label>Reference No: {referenceNo}</Label>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label>File Sent On: {moment(dateSent).format("LLL")}</Label>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label>
                File Received On: {moment(dateReceived).format("LLL")}
              </Label>
            </Col>
          </Row>
        </div>
      </CardBody>

      <CardBody>
        <Row>
          <div className="m-4">
            <Col>
              {fileTrackerLog.length > 0 ? (
                <DataTable
                  columns={columns}
                  data={fileTrackerLog.sort((a, b) =>
                    b.id > a.id ? -1 : 1
                  )}
                  noDataComponent="No available data"
                  pagination
                />
              ) : (
                <Spinner />
              )}
            </Col>
          </div>
        </Row>
      </CardBody>
    </Card>
  );
};

export default Main;
